.resultItem2 {
    display: flex;
    flex-grow: 1;
    width: 22%;
    /* height: 12rem;
    min-width: 280px;
    min-height: 200px; */
    flex-direction: column;
    align-items: center;
    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding-top: 5px;
    border-radius: 0.5rem;
    vertical-align: middle;
    margin-top: 20px;
}

.resultItemTitle {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.amountNumber {
    font-family: "Raleway", sans-serif;
    font-size: 30px; /* changed 2022-02-21 */
    font-weight: 800;
}
